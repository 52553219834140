import { FunctionComponent } from 'react';

const SvgCloseMenuAlt: FunctionComponent = () => {
  return (
    <span className='anticon' role='img' aria-label='close-menu-alt'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1021.16 702.05'
        width='1em'
        height='1em'
      >
        <path
          d='M989.25 351.03H478.67m223.38-223.38L478.67 351.03 702.05 574.4M287.2 31.91H31.91M287.2 351.03H31.91M287.2 670.14H31.91'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={64}
        />
      </svg>
    </span>
  );
};

export default SvgCloseMenuAlt;
