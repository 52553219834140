import { FunctionComponent } from 'react';

const SvgOpenMenuAlt: FunctionComponent = () => {
  return (
    <span className='anticon' role='img' aria-label='open-menu-alt'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1014.82 697.7'
        width='1em'
        height='1em'
      >
        <path
          d='M475.69 348.85h507.38m-222 221.99l222-222-222-222M31.72 665.98h253.71M31.72 31.69h253.71M31.72 348.85h253.71'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={64}
        />
      </svg>
    </span>
  );
};

export default SvgOpenMenuAlt;
