import { FunctionComponent } from 'react';

const SvgTagAlt: FunctionComponent = () => {
  return (
    <span className='anticon' role='img' aria-label='tag-alt'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1013.3 1013.3'
        width='1em'
        height='1em'
      >
        <path
          d='M959.6 722.18L722.18 959.6a56 56 0 01-79.17 0L157.36 473.94a28.07 28.07 0 01-8.14-21.64l17.3-259.65a28.08 28.08 0 0126.13-26.13l259.65-17.3a28.07 28.07 0 0121.64 8.14L959.6 643.01a56 56 0 010 79.17zM298.45 298.45L37.31 37.31'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={74}
        />
      </svg>
    </span>
  );
};

export default SvgTagAlt;
