import { FunctionComponent } from 'react';

const SvgHelpCircleAlt: FunctionComponent = () => {
  return (
    <span className='anticon' role='img' aria-label='help-circle-alt'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='1em'
        height='1em'
        viewBox='0 0 1019.62 1019.62'
      >
        <path
          d='M36.42 509.81a473.38 473.38 0 10138.65-334.74A473.39 473.39 0 0036.42 509.81z'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='72.83'
        />
        <path
          d='M400.57 400.57a109.25 109.25 0 11109.24 109.24v72.83'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='72.83'
        />
        <path
          d='M509.81 679.65a42.59 42.59 0 1030.06 12.48 42.58 42.58 0 00-30.06-12.48z'
          fill='currentColor'
        />
      </svg>
    </span>
  );
};

export default SvgHelpCircleAlt;
