import Lottie from 'react-lottie';

import loaderLottie from '@/lottie-animations/loader.json';

const ApplicationLoader = (): JSX.Element => {
  return (
    <Lottie
      options={{
        animationData: loaderLottie,
        autoplay: true,
        loop: true,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
      }}
      width={200}
      height={200}
    />
  );
};
export default ApplicationLoader;
