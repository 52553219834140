import '@/theme/dark/index.less';
import '@/theme/light/index.less';
import '@/theme/tailwind/index.less';

import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import {
  Alert,
  Button,
  ConfigProvider as AntdConfigProvider,
  Divider,
  Layout,
  Result,
  Skeleton,
} from 'antd';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import {
  ComponentClass,
  FunctionComponent,
} from 'react';

import ApplicationLoader from '@/components/ApplicationLoader';
import Favicons from '@/components/Favicons';
import {
  CloseMenuAlt,
  OpenMenuAlt,
} from '@/components/Icons';
import { GrowFlowLogo } from '@/components/Logo';
import { ProfileMenu } from '@/components/ProfileMenu';
import SideNav from '@/components/SideNav';
import constants from '@/constants';
import {
  ApplicationProvider,
  useApplication,
} from '@/context/application';
import { QueryParamProvider } from '@/context/queryParams';
import { useStorage } from '@/hooks/useStorage';
import strings from '@/strings';

interface ApplicationComponentProps {
  Component: ComponentClass;
  pageProps: never;
}

const ApplicationError: FunctionComponent = () => {
  return (
    <>
      <Alert
        message={strings.oops}
        description={strings.applicationError}
        type='error'
        showIcon
      />
      <Skeleton active />
    </>
  );
};

const CatastrophicError: FunctionComponent = () => {
  return (
    <Result
      status='error'
      title={strings.applicationStartupError}
      subTitle={strings.errorMessages.criticalBootError}
      extra={[
        <Button
          type='primary'
          key='console'
          onClick={() => window.location.reload()}
        >
          {strings.reloadPage}
        </Button>,
      ]}
    />
  );
};

const ApplicationLayout: FunctionComponent<ApplicationComponentProps> = ({
  Component,
  ...restProps
}) => {
  const { error, initializing } = useApplication();

  const [collapsed, setCollapsed] = useStorage(
    constants.SIDER_COLLAPSED,
    false,
  );

  return (
    <Layout className='min-h-screen' hasSider={true}>
      <Layout.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        width={200}
        trigger={null}
        className='shadow-md min-h-full'
        data-testid='sider'
        data-testid-collapsed={collapsed}
      >
        <div className='flex flex-col h-full'>
          <Layout.Header className='flex flex-row flex-shrink-0 items-center justify-around p-6'>
            <GrowFlowLogo size={collapsed ? 'small' : 'large'} />
          </Layout.Header>

          <div className='flex justify-around'>
            <Divider className='min-w-min w-3/4 m-0' />
          </div>

          <SideNav collapsed={collapsed} />
        </div>
      </Layout.Sider>

      <Layout>
        <Layout.Header className='flex flex-row min-w-full justify-between items-center px-6 shadow-sm'>
          <Button
            type='text'
            icon={collapsed ? <OpenMenuAlt /> : <CloseMenuAlt />}
            onClick={() => setCollapsed(!collapsed)}
            className='-ml-4'
            data-testid='sider-collapse-button'
            data-testid-collapsed={collapsed}
          />
          {!initializing && !error && <ProfileMenu />}
        </Layout.Header>
        <Layout.Content className='pt-4'>
          <Sentry.ErrorBoundary fallback={() => <ApplicationError />}>
            <ApplicationContent Component={Component} {...restProps} />
          </Sentry.ErrorBoundary>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

const ApplicationContent: FunctionComponent<ApplicationComponentProps> = ({
  Component,
  ...restProps
}) => {
  const {
    authenticating, initializing, error, apolloClient,
  } =
    useApplication();

  if (initializing || authenticating) {
    return <ApplicationLoader />;
  } else if (error || !apolloClient) {
    return (
      <div className='m-4'>
        <ApplicationError />
      </div>
    );
  } else {
    return (
      <ApolloProvider client={apolloClient}>
        <Component {...restProps} />
      </ApolloProvider>
    );
  }
};

const ApplicationComponent: FunctionComponent<ApplicationComponentProps> = (
  pageProps,
) => {
  return (
    <>
      <Favicons />
      <Head>
        <title>{strings.growFlow}</title>
      </Head>

      {/*<React.Suspense maxDuration={300} fallback={'loading...'}>*/}
      {/*</React.Suspense>*/}
      <Sentry.ErrorBoundary fallback={() => <CatastrophicError />}>
        <AntdConfigProvider>
          <QueryParamProvider>
            <ApplicationProvider>
              <ApplicationLayout {...pageProps} />
            </ApplicationProvider>
          </QueryParamProvider>
        </AntdConfigProvider>
      </Sentry.ErrorBoundary>
    </>
  );
};

export default dynamic(() => Promise.resolve(ApplicationComponent), { ssr: false });
