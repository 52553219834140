import { FC } from 'react';

import strings from '@/strings';

const GrowFlowLogo: FC<{ size: string }> = ({ size }) => {
  return size === 'small' ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='33.86'
      viewBox='0 0 272 307'
      fill='none'
    >
      <title>{strings.growFlow}</title>
      <path
        d='M44.896 72.773c0-7.494 2.13-13.072 6.513-17.057l.001.002c4.424-4.015 11.231-6.05 20.236-6.05 9.007 0 15.815 2.035 20.234 6.05 4.386 3.979 6.518 9.557 6.518 17.055 0 7.495-2.132 13.076-6.517 17.058-4.418 4.01-11.226 6.043-20.235 6.043-9.012 0-15.829-1.995-20.258-5.928-4.369-3.885-6.492-9.504-6.492-17.173zm58.187 65.201H52.194c-.856 0-1.698.057-2.546.118l-.509.041c-2.748-.019-4.883-.669-6.346-1.933-1.394-1.202-2.042-2.657-2.042-4.582 0-2.023.69-3.484 2.166-4.593 1.714-1.283 4.222-1.933 7.457-1.933h22.654c19.909 0 35.655-4.677 46.799-13.902 11.319-9.371 17.059-22.298 17.059-38.417 0-12.453-1.615-22.943-9.417-31.854 7.72-2.439 15.646-6.822 20.412-12.405 5.77-6.766 8.696-15.394 8.696-25.645V0h-37.42l-.228 2.617c-.327 3.76-1.766 7.748-4.277 11.858-2.339 3.817-4.827 7.684-9.741 10.764-9.073-2.24-22.808-4.515-33.544-4.515-20.251 0-36.306 4.621-47.718 13.733C12.025 43.735 6.13 56.627 6.13 72.773c0 8.741 1.932 16.737 5.739 23.763 2.87 5.306 6.714 9.948 11.443 13.828-5.022 1.841-9.486 4.439-13.295 7.742-5.513 4.791-8.307 10.919-8.307 18.212 0 9.52 4.05 17.359 12.382 23.967 7.379 5.858 15.427 9.772 23.922 11.638 7.872 1.725 16.257 1.352 24.384.993 3.31-.146 6.438-.286 9.521-.286h31.165c10.213 0 18.522 8.574 18.522 19.116 0 10.541-8.309 19.116-18.522 19.116H52.194c-10.213 0-18.52-8.575-18.52-19.116 0-2.396.458-4.785 1.382-7.157l.468-1.298-1.341-.281c-4.746-.992-8.525-2.132-11.89-3.585-5.617-2.424-10.89-5.879-15.672-10.265l-1.24-1.046-.667 1.508C1.587 176.68 0 184.126 0 191.746c0 29.651 23.414 53.773 52.194 53.773h50.889c28.781 0 52.195-24.122 52.195-53.773 0-29.65-23.414-53.772-52.195-53.772z'
        fill='#030047'
      />
      <path
        d='M252.098 61.54h14.852L272 32.205c-3.371-1.223-7.774-2.339-13.217-3.359-5.452-1.018-10.45-1.53-15-1.53-14.068 0-24.908 4.23-32.53 12.682-7.626 8.457-12.428 19.001-14.407 31.629l-2.968 18.335h-46.18c-3.158 11.463-9.456 21.283-18.688 28.911a61.178 61.178 0 01-7.545 5.317h66.472l-23.171 134.764c-1.585 8.757-6.533 13.141-14.852 13.141h-14.852l-5.051 29.336c3.371 1.221 7.775 2.341 13.218 3.363 5.451 1.016 10.45 1.527 15.004 1.527 14.056 0 24.899-4.229 32.525-12.682 7.623-8.457 12.424-18.999 14.408-31.627l23.463-137.822h27.625l5.945-34.228h-27.625l2.671-15.28c1.582-8.757 6.538-13.14 14.853-13.14z'
        fill='#6B57F8'
      />
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1192'
      height='278'
      viewBox='0 0 1192 278'
      fill='none'
    >
      <title>{strings.growFlow}</title>
      <path
        d='M243.842 97.633c3.884-12.184 9.764-22.36 17.631-30.42 1.37-1.401 2.819-2.72 4.305-4-15.924-6.268-25.063-5.971-30.12-5.971-8.572 0-15.486 2.554-20.746 7.664-5.262 5.115-9.549 12.089-12.859 20.934h-2.046l-3.213-25.054c-.001-.004-.004-.006-.008-.006h-34.18c-.005 0-.007.004-.007.008v155.066c0 .001.002.006.007.006h40.024c.004 0 .008-.005.008-.006v-74.881c0-14.542 2.923-25.402 8.767-32.579 5.842-7.173 15.293-10.76 28.349-10.76h4.088M353.759 174.121c5.705-7.378 8.551-18.548 8.551-33.503v-4.6c0-14.951-2.846-26.117-8.551-33.5-5.7-7.379-14.919-11.07-27.646-11.07-12.734 0-21.905 3.692-27.508 11.07-5.609 7.383-8.408 18.549-8.408 33.5v4.6c0 14.955 2.799 26.125 8.408 33.503 5.603 7.383 14.774 11.07 27.508 11.07 12.727 0 21.946-3.687 27.646-11.07zm-84.94 24.01c-12.541-12.842-18.814-32.781-18.814-59.812s6.273-46.967 18.814-59.814c12.543-12.841 31.639-19.265 57.294-19.265 25.653 0 44.794 6.424 57.434 19.265 12.636 12.847 18.956 32.783 18.956 59.814s-6.32 46.97-18.956 59.812c-12.64 12.845-31.781 19.267-57.434 19.267-25.655 0-44.751-6.422-57.294-19.267M402.087 60.78h41.206l16.657 63.683c1.755 6.883 4.772 21.721 9.059 44.52h1.171c4.285-21.03 7.401-35.772 9.352-44.225l15.782-63.978h41.204l16.658 64.271c2.341 9.044 5.552 23.689 9.646 43.932h1.168c3.699-18.87 6.818-33.51 9.35-43.932l15.782-64.271h37.993l-45.59 155.08h-41.5l-17.531-74.002c-2.147-9.436-4.488-20.832-7.015-34.199h-1.462c-1.755 13.169-3.8 24.47-6.137 33.903l-18.415 74.298h-41.496L402.087 60.78'
        fill='#030047'
      />
      <path
        d='M767.026 1.367h40.038L767.709 216.75h-40.037L767.026 1.367zM611.853 274.821c-5.357-1.011-9.687-2.119-13.005-3.328l4.97-29.018h14.611c8.185 0 13.053-4.336 14.612-12.998l23.14-134.636h-24.613l9.707-33.857h20.75l2.58-16.81c1.946-12.492 6.671-22.92 14.173-31.285C686.279 4.529 696.941.344 710.781.344c4.478 0 9.395.504 14.757 1.511 5.357 1.012 13.635 4.108 16.953 5.317L735.547 34.2h-16.585c-8.181 0-13.056 4.334-14.614 12.998l-2.285 13.785h37.769l-8.542 33.857h-35.076L672.788 232.5c-1.95 12.492-6.675 22.92-14.173 31.286-7.505 8.361-18.173 12.544-32.001 12.544-4.48 0-9.399-.504-14.761-1.509M901.499 174.051c7.141-7.494 11.659-19.021 13.565-34.582.76-5.187 1.145-10.756 1.145-16.713-.191-9.798-3.193-17.481-8.998-23.054-5.813-5.571-13.856-8.357-24.139-8.357-12.952 0-22.994 3.746-30.135 11.237-7.141 7.493-11.667 19.021-13.566 34.582-.764 5.187-1.145 10.761-1.145 16.714.188 9.799 3.186 17.485 8.997 23.055 5.81 5.573 13.855 8.356 24.141 8.356 12.945 0 22.994-3.746 30.135-11.238zm-67.7 35.446c-10.19-5.377-18.282-12.869-24.281-22.479-5.996-9.603-9.097-20.461-9.282-32.564 0-9.409.661-17.767 1.999-25.07 3.806-22.668 12.567-40.058 26.281-52.162 13.709-12.102 32.657-18.153 56.843-18.153 13.14 0 24.848 2.692 35.133 8.068 10.283 5.38 18.427 12.873 24.423 22.478 5.998 9.61 9.093 20.46 9.283 32.564 0 9.415-.667 17.772-1.998 25.072-3.813 22.672-12.618 40.055-26.422 52.16-13.809 12.103-32.899 18.154-57.274 18.154-12.952 0-24.518-2.692-34.705-8.068M965.976 60.78h39.454l5.55 63.683c.59 6.883 1.17 21.721 1.75 44.52h1.17c7.6-21.03 13.15-35.772 16.66-44.225l26.89-63.978h41.21l5.26 64.271c.77 9.044 1.65 23.689 2.63 43.932h1.17c6.42-18.87 11.88-33.51 16.36-43.932l27.18-64.271h39.75l-72.77 155.08h-41.5l-4.68-74.002c-.58-9.436-.88-20.832-.88-34.199h-1.45c-3.91 13.169-7.9 24.47-11.99 33.903l-31.56 74.298h-41.501L965.976 60.78z'
        fill='#6B57F8'
      />
      <path
        d='M44.896 104.774c0-7.494 2.13-13.072 6.513-17.056l.001.001c4.424-4.015 11.231-6.049 20.236-6.049 9.007 0 15.815 2.034 20.234 6.05 4.386 3.978 6.518 9.556 6.518 17.054 0 7.496-2.132 13.076-6.517 17.058-4.418 4.01-11.226 6.043-20.235 6.043-9.012 0-15.829-1.994-20.258-5.928-4.369-3.885-6.492-9.503-6.492-17.173zm58.187 65.201H52.194c-.856 0-1.698.057-2.546.118l-.509.042c-2.748-.02-4.883-.67-6.346-1.934-1.394-1.202-2.042-2.657-2.042-4.581 0-2.024.69-3.484 2.166-4.594 1.714-1.283 4.222-1.932 7.457-1.932h22.654c19.909 0 35.655-4.678 46.799-13.902 11.319-9.372 17.059-22.299 17.059-38.418 0-12.453-1.615-22.943-9.417-31.853 7.72-2.44 15.646-6.822 20.412-12.406 5.77-6.766 8.696-15.394 8.696-25.645v-2.869h-37.42l-.228 2.617c-.327 3.759-1.766 7.747-4.277 11.857-2.339 3.817-4.827 7.684-9.741 10.765-9.073-2.24-22.808-4.516-33.544-4.516-20.251 0-36.306 4.621-47.718 13.733-11.625 9.28-17.52 22.172-17.52 38.317 0 8.742 1.932 16.738 5.739 23.764 2.87 5.305 6.714 9.947 11.443 13.828-5.022 1.84-9.486 4.438-13.295 7.741-5.513 4.791-8.307 10.919-8.307 18.212 0 9.52 4.05 17.359 12.382 23.967 7.379 5.858 15.427 9.772 23.922 11.638 7.872 1.725 16.257 1.352 24.384.994 3.31-.147 6.438-.286 9.521-.286h31.165c10.213 0 18.522 8.573 18.522 19.115 0 10.541-8.309 19.116-18.522 19.116H52.194c-10.213 0-18.52-8.575-18.52-19.116 0-2.395.458-4.785 1.382-7.157l.468-1.298-1.341-.28c-4.746-.992-8.525-2.133-11.89-3.586-5.617-2.424-10.89-5.879-15.672-10.265l-1.24-1.046-.667 1.508C1.587 208.682 0 216.127 0 223.747c0 29.651 23.414 53.773 52.194 53.773h50.889c28.781 0 52.195-24.122 52.195-53.773 0-29.65-23.414-53.772-52.195-53.772z'
        fill='#030047'
      />
    </svg>
  );
};

export { GrowFlowLogo };
