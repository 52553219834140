import { useRouter } from 'next/router';
import React, {
  memo,
  useMemo,
} from 'react';
import { QueryParamProvider as ContextProvider } from 'use-query-params';

// This was copied pasted from: https://github.com/pbeshai/use-query-params/issues/13#issuecomment-684782229
// I spent a little time writing a custom useQueryParams hook, it is actually fairly easy to write... but a massive pain to test.
// So I opted to use a community supported solution.  However, this solution does not support next.js out of the box, due to next.js having its own router and SSR.
// If you dig into this solution, it is simply telling the use-query-params hook HOW to do navigation in the app and HOW to get the current window.location.search.
// When use-query-params supports next.js out of the box, remove this code.

export const QueryParamProviderComponent = (props: {
  children?: React.ReactNode;
}): JSX.Element => {
  const { children, ...rest } = props;
  const router = useRouter();
  const match = router.asPath.match(/[^?]+/);
  const pathname = match ? match[0] : router.asPath;

  const location = useMemo(
    () =>
      process.browser ?
        window.location :
        ({ search: router.asPath.replace(/[^?]+/u, '') } as Location),
    [router.asPath],
  );

  const history = useMemo(
    () => ({
      location,
      push: ({ search }: Location) =>
        router.push(
          { pathname: router.pathname, query: router.query },
          { pathname, search },
          { scroll: false, shallow: true },
        ),
      replace: ({ search }: Location) => {
        router.replace(
          { pathname: router.pathname, query: router.query },
          { pathname, search },
          { scroll: false, shallow: true },
        );
      },
    }),
    [
      pathname,
      router.pathname,
      router.query,
      location.pathname,
    ],
  );

  return (
    <ContextProvider {...rest} history={history} location={location}>
      {children}
    </ContextProvider>
  );
};

export const QueryParamProvider = memo(QueryParamProviderComponent);
