import { FunctionComponent } from 'react';

const SvgMultipleUsersAlt: FunctionComponent = () => {
  return (
    <span className='anticon' role='img' aria-label='multiple-users-alt'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1015.26 942.74'
        width='1em'
        height='1em'
      >
        <path
          d='M199.42 199.43a163.19 163.19 0 1012.42-62.44 163.13 163.13 0 00-12.42 62.44zm489.5 707.06H36.26V797.71a326.33 326.33 0 11652.66 0zM614.95 40.62a165.19 165.19 0 0137.71-4.36 163.17 163.17 0 110 326.34 167.07 167.07 0 01-34.81-3.72m105.59 120.18A326.34 326.34 0 01979 797.71v108.78H834'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={74}
        />
      </svg>
    </span>
  );
};

export default SvgMultipleUsersAlt;
