import { FunctionComponent } from 'react';

const SvgMoneyBagAlt: FunctionComponent = () => {
  return (
    <span className='anticon' role='img' aria-label='money-bag-alt'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1020.79 1099.29'
        width='1em'
        height='1em'
      >
        <path
          d='M634.77 296.34l86.38-181.93A51.82 51.82 0 00677 39.26H343.77a51.83 51.83 0 00-44 75.15l86.37 181.93C122.49 371.72 39.26 685 39.26 873.17c0 215.15 210.91 185.47 471.13 185.47s471.14 29.68 471.14-185.47c0-188.17-83.24-501.45-346.76-576.83zM510.71 846.86v79.55M510.71 453.62v73.66'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='78.52'
        />
        <path
          d='M432.74 811.37a98.26 98.26 0 0077.65 35.49A87.49 87.49 0 00603.84 767a87.48 87.48 0 00-93.45-80A87.49 87.49 0 01417 607.13a87.39 87.39 0 0193.44-79.85 98.45 98.45 0 0177.74 35.49'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='78.52'
        />
      </svg>
    </span>
  );
};

export default SvgMoneyBagAlt;
