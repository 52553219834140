import { AccountDto, LicenseGroup, Maybe } from '@/graphql';
import { User } from '@auth0/auth0-spa-js';

export const permissionConfig = {
  tenantPermissionKey: 'https://growflow.com/permissions'
};

export enum TenantPermissions {
  WHOLESALE_SUPERADMIN = 'write:wholesale:organization:*'
}

export const GrowMenuPermissions = [
  LicenseGroup.Producer,
  LicenseGroup.ProducerProcessor,
  LicenseGroup.MicroBusiness, LicenseGroup.Tribal,
];

export const hasPermission = (
  currentUser: any,
  permissionNeeded: TenantPermissions,
  keyToCheck: string
): boolean => {
  if (!currentUser || !currentUser[keyToCheck]) return false;
  return currentUser[keyToCheck].indexOf(permissionNeeded) > -1;
};

export const hasTenantPermission = (
  currentUser: any,
  permissionNeeded: TenantPermissions,
): boolean => {
  return hasPermission(currentUser, permissionNeeded, permissionConfig.tenantPermissionKey)
};

// ----------------------------- Helper/Convenience Methods -----------------
// Moved these here as soon-ish, we will have permissions coming from Tenant and it will be nice to have all these bits in once place so we can swap implementation later.

export function accountHasPermissionToSeeGrowRoutes(account: Maybe<AccountDto>) {
  const licenseType = account?.vendor?.licenseType;

  if (licenseType == null)
    return false;

  const keyToCheck = 'licenseGroupType';
  const permissionsNeeded = GrowMenuPermissions;

  if (Array.isArray(licenseType[keyToCheck])) {
    return !!permissionsNeeded.find((permission) => licenseType[keyToCheck].includes(permission));
  }

  return permissionsNeeded.includes(licenseType[keyToCheck]);
}

export function userIsSuperAdmin(user: User | null | undefined) {
  if (!user) return false;
  return hasTenantPermission(user, TenantPermissions.WHOLESALE_SUPERADMIN);
}