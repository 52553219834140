import { FunctionComponent } from 'react';

const SvgSuitcaseAlt: FunctionComponent = () => {
  return (
    <span className='anticon' role='img' aria-label='suitcase-alt'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1015.41 942.88'
        width='1em'
        height='1em'
      >
        <path
          d='M36.27 253.85h942.88v652.77H36.27zm0 290.12h942.88m-471.44-72.53V616.5M725.3 253.85c0-58-21.76-116-65.28-152.31q-65.28-65.28-152.31-65.22h0c-58 0-116 21.75-152.31 65.27q-65.25 65.23-65.25 152.26'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={74}
        />
      </svg>
    </span>
  );
};

export default SvgSuitcaseAlt;
