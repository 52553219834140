import {
  Divider,
  Menu,
} from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MenuInfo } from 'rc-menu/lib/interface';
import {
  FC,
  useEffect,
  useState,
} from 'react';
import { If } from 'react-if';

import {
  CannabisAlt,
  CaretDownAlt,
  DashboardAlt,
  HelpCircleAlt,
  MoneyBagAlt,
  MultipleUsersAlt,
  ShippingBoxAlt,
  SuitcaseAlt,
  TagAlt,
} from '@/components/Icons';
import constants from '@/constants';
import { useApplication } from '@/context/application';
import externalUrls from '@/externalUrls';
import { accountHasPermissionToSeeGrowRoutes } from '@/permissions';
import Routes from '@/routes';
import strings from '@/strings';

interface NavbarMenuSubMenuOptions {
  hasAccess: boolean | (() => boolean);
  link?: string;
  route?: string;
  text: string;
}

interface NavbarMenuItem {
  hasAccess: boolean | (() => boolean);
  icon?: JSX.Element;
  key: string;
  link?: string;
  subMenuItems?: NavbarMenuSubMenuOptions[];
  text: string;
}

const SideNav: FC<{ collapsed: boolean }> = ({ collapsed }) => {
  const {
    account, error, initializing, checkFlag,
  } = useApplication();

  const router = useRouter();

  const navbarMenuOptions: NavbarMenuItem[] = [
    {
      hasAccess: true,
      icon: <DashboardAlt />,
      key: 'dashboard',
      subMenuItems: [
        {
          hasAccess: true,
          link: externalUrls.dashboard(),
          text: strings.dashboard,
        },
        {
          hasAccess: true,
          link: externalUrls.insights(account?.organization?.organizationKey || ''),
          text: strings.insights,
        },
      ],
      text: strings.dashboard,
    },
    {
      hasAccess: accountHasPermissionToSeeGrowRoutes(account),
      icon: <CannabisAlt />,
      key: 'grow',
      subMenuItems: [
        {
          hasAccess: true,
          link: externalUrls.grow(),
          text: strings.overview,
        },
        {
          hasAccess: true,
          link: externalUrls.plants(),
          text: strings.plants,
        },
        {
          hasAccess: true,
          link: externalUrls.additives(),
          text: strings.additives,
        },
      ],
      text: 'Grow',
    },
    {
      hasAccess: true,
      icon: <ShippingBoxAlt />,
      key: 'orders',
      subMenuItems: [
        {
          hasAccess: true,
          link: externalUrls.salesOrders(),
          text: strings.overview,
        },
        {
          hasAccess: true,
          link: externalUrls.newOrder(),
          text: strings.newOrder,
        },
        {
          hasAccess: true,
          link: externalUrls.newPreorder(),
          text: strings.newPreorder,
        },
        {
          hasAccess: true,
          link: externalUrls.createSample(),
          text: strings.newQaSample,
        },
        {
          hasAccess: true,
          link: externalUrls.salesHistory(),
          text: strings.salesHistory,
        },
        {
          hasAccess: true,
          link: externalUrls.inboundOrders(),
          text: strings.inboundOrders,
        },
        {
          hasAccess: true,
          route: Routes.orders.printLabels,
          text: strings.printLabels,
        },
      ],
      text: strings.orders,
    },
    {
      hasAccess: true,
      icon: <TagAlt />,
      key: 'inventory',
      subMenuItems: [
        {
          hasAccess: true,
          link: externalUrls.inventory(),
          text: strings.overview,
        },
        {
          hasAccess: true,
          link: externalUrls.batch(),
          text: strings.batches,
        },
        {
          hasAccess: true,
          link: externalUrls.conversions(),
          text: strings.conversions,
        },
        // {
        //   hasAccess: checkFlag(constants.PERPETUAL_INVENTORY_FEATURE_FLAG),
        //   route: Routes.inventory.perpetual.index,
        //   text: strings.perpetual,
        // },
      ],
      text: strings.inventory,
    },
    {
      hasAccess: true,
      icon: <MultipleUsersAlt />,
      key: 'vendors',
      link: externalUrls.vendors(),
      text: strings.vendors,
    },
    {
      hasAccess: true,
      icon: <SuitcaseAlt />,
      key: 'admin',
      subMenuItems: [
        {
          hasAccess: true,
          link: externalUrls.admin(),
          text: strings.admin,
        },
        {
          hasAccess: !checkFlag(constants.ADMIN_STRAINS_FEATURE_FLAG),
          route: externalUrls.strain(),
          text: strings.strains,
        },
        {
          hasAccess: checkFlag(constants.ADMIN_STRAINS_FEATURE_FLAG),
          route: Routes.admin.strains,
          text: strings.strains,
        },
        {
          hasAccess: !checkFlag(constants.ADMIN_EMPLOYEES_FEATURE_FLAG),
          link: externalUrls.employee(),
          text: strings.employees,
        },
        {
          hasAccess: checkFlag(constants.ADMIN_EMPLOYEES_FEATURE_FLAG),
          link: Routes.admin.employees,
          text: strings.employees,
        },
        {
          hasAccess: true,
          link: externalUrls.vehicles(),
          text: strings.vehicles,
        },
        {
          hasAccess: checkFlag(constants.ADMIN_ROOMS_FEATURE_FLAG),
          route: Routes.admin.rooms,
          text: strings.rooms,
        },
        {
          hasAccess: !checkFlag(constants.ADMIN_ROOMS_FEATURE_FLAG),
          link: externalUrls.rooms(),
          text: strings.rooms,
        },
        {
          hasAccess: true,
          link: externalUrls.labels(),
          text: strings.labelsTemplates,
        },
        {
          hasAccess: true,
          link: externalUrls.products(),
          text: strings.products,
        },
        {
          hasAccess: true,
          link: externalUrls.store(),
          text: strings.store,
        },
        {
          hasAccess: true,
          link: externalUrls.qaResults(),
          text: strings.qaSamples,
        },
        {
          hasAccess: true,
          link: externalUrls.auditLog(),
          text: strings.auditLog,
        },
        {
          hasAccess: true,
          link: externalUrls.printnode(),
          text: strings.printing,
        },
        {
          hasAccess: true,
          link: externalUrls.import(),
          text: strings.import,
        },
      ],
      text: strings.admin,
    },
  ];

  const defaultOpenKey = router.pathname.split('/').filter(Boolean).shift();

  const [currentRoute, setCurrentRoute] = useState(router.pathname);

  const handleClick = (e: MenuInfo) => {
    setCurrentRoute(e.key.toString());
  };

  useEffect(() => {
    setCurrentRoute(router.pathname);
  }, [router.pathname]);

  return (
    <div className='max-h-screen overflow-x-hidden overflow-y-auto flex flex-col flex-grow'>
      <div className='flex flex-col flex-grow'>
        <If condition={!initializing && !error && !!account}>
          <Menu
            defaultOpenKeys={[defaultOpenKey || '']}
            expandIcon={collapsed ? <></> : <CaretDownAlt />}
            inlineCollapsed={collapsed}
            mode='inline'
            onClick={handleClick}
            selectedKeys={[currentRoute]}
            style={{ borderRight: 'none' }}
            data-testid='sider-menu'
          >
            {navbarMenuOptions
              .filter((menuItem) => menuItem.hasAccess)
              .map((menuItem) => {
                if (menuItem.subMenuItems) {
                  return (
                    <Menu.SubMenu
                      key={menuItem.key}
                      title={collapsed ? '' : menuItem.text}
                      icon={menuItem.icon}
                    >
                      {menuItem.subMenuItems
                        .filter((subMenuItem) => subMenuItem.hasAccess)
                        .map((subMenuItem, _index) => (
                          <Menu.Item
                            key={subMenuItem.route || subMenuItem.link}
                          >
                            {subMenuItem.route ? (
                              <Link href={subMenuItem.route}>
                                {subMenuItem.text}
                              </Link>
                            ) : (
                              <a href={subMenuItem.link}>{subMenuItem.text}</a>
                            )}
                          </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                  );
                } else {
                  return (
                    <Menu.Item
                      icon={menuItem.icon}
                      key={menuItem.key}
                      title={menuItem.text}
                    >
                      <a href={menuItem.link}>
                        {collapsed ? '' : menuItem.text}
                      </a>
                    </Menu.Item>
                  );
                }
              })}
          </Menu>
        </If>
      </div>

      <Menu
        expandIcon={<CaretDownAlt />}
        inlineCollapsed={collapsed}
        mode='inline'
        onClick={handleClick}
        selectedKeys={[currentRoute]}
        style={{ borderRight: 'none' }}
        className='mb-6'
      >
        <div className='flex justify-around'>
          <Divider className='min-w-min w-3/4 m-0' />
        </div>

        <Menu.Item
          icon={<MoneyBagAlt />}
          key='refer-and-earn'
          title={strings.referAndEarn}
        >
          <a href={externalUrls.referer()}>
            {collapsed ? '' : strings.referAndEarn}
          </a>
        </Menu.Item>
        <Menu.Item
          icon={<HelpCircleAlt />}
          key='help-docs'
          title={strings.helpDocs}
        >
          <a href={externalUrls.help()}>{collapsed ? '' : strings.helpDocs}</a>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default SideNav;
