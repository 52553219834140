import { FunctionComponent } from 'react';

const SvgShippingBoxAlt: FunctionComponent = () => {
  return (
    <span className='anticon' role='img' aria-label='shipping-box-alt'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1024 1024'
        width='1em'
        height='1em'
      >
        <path
          fill='none'
          stroke='currentColor'
          strokeWidth={74}
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M71.7 352.6c-1.6 4.9-2.6 10.1-3.1 15.2v541.8c0 19.7 7.8 38.6 21.8 52.5 13.9 13.9 32.8 21.8 52.5 21.8h742.7c19.7 0 38.6-7.8 52.5-21.8s21.8-32.8 21.8-52.5V367.8c-.5-5.2-1.5-10.3-3.1-15.2H71.7zm89-215l-43 100.5-43.1 100.4c-1.4 4.6-2.4 9.3-2.9 14h885c-.5-4.8-1.5-9.5-2.9-14l-43.1-100.4-43-100.5c-5.7-13.4-15.2-24.7-27.4-32.7-12.1-8-26.3-12.3-40.9-12.3H229c-14.5 0-28.7 4.3-40.9 12.3-12.1 8-21.7 19.4-27.4 32.7zm353.6-45v260m111.4 445.6h148.5'
        />
      </svg>
    </span>
  );
};

export default SvgShippingBoxAlt;
