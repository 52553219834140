import { FunctionComponent } from 'react';

const SvgCannabisAlt: FunctionComponent = () => {
  return (
    <span className='anticon' role='img' aria-label='cannabis-alt'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 922 1018.92'
        width='1em'
        height='1em'
      >
        <path
          d='M372.1 878.27c-19.85 35.6-62.66 54.08-122.39 60.16-2.94.3-5.53-2.1-5.81-5.36a8.36 8.36 0 01.07-1.66c9.51-58.6 44.59-109.28 98.38-154.16a6.39 6.39 0 001-8.32 5.06 5.06 0 00-5.23-2.23c-116.07 25.91-217.67 12.62-303.75-42.86a6.32 6.32 0 01-1.87-8.08 5.87 5.87 0 011.38-1.77c76.22-62.23 170.42-76.35 283.5-39.87 2.83.88 5.78-1 6.57-4.13a6.3 6.3 0 00-1.83-6.24C197.88 556.25 106.46 439.41 58.7 310.1a6.18 6.18 0 012.84-7.75 4.85 4.85 0 013.34-.25C188 337.43 290.46 440.7 381.79 580.52a5 5 0 007.43 1.5 6.23 6.23 0 002.21-5.95C354.95 335.9 379.13 156 459.18 31.78m91.42 846c20 35.42 63 53.69 122.74 59.4 2.92.27 5.49-2.12 5.77-5.36a6.51 6.51 0 00-.09-1.65c-9.78-58.55-45.15-109-99.11-153.55a6.39 6.39 0 01-1-8.31 5.1 5.1 0 015.24-2.26c116.14 25.11 217.73 11.22 303.47-44.78a6.29 6.29 0 001.88-8.11h0a5.6 5.6 0 00-1.43-1.76c-76.48-61.76-170.76-75.22-283.7-38.11-2.83.94-5.8-.85-6.64-4a6.3 6.3 0 011.82-6.36C723.3 554.62 814.07 437.15 861.28 307.64a6.17 6.17 0 00-3-7.7h0a4.9 4.9 0 00-3.35-.21C732.02 335.89 630.06 439.74 539.39 580.1a5 5 0 01-7.44 1.48 6.25 6.25 0 01-2.2-5.89c35.2-240.49 10.12-420.27-70.54-544m.57 857.87v97.53'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={64}
        />
      </svg>
    </span>
  );
};

export default SvgCannabisAlt;
