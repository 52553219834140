import { FunctionComponent } from 'react';

const SvgCaretDownAlt: FunctionComponent = () => {
  return (
    <i
      className='ant-menu-submenu-expand-icon'
      role='img'
      aria-label='caret-down-alt'
    >
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 10'>
        <path
          d='M.5.5l4.73 4.73.12.08h.3l.12-.08L10.5.5'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </i>
  );
};

export default SvgCaretDownAlt;
