import {
  ContainerOutlined,
  DashboardOutlined,
  ExclamationCircleOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  SwapOutlined,
  UnderlineOutlined,
} from '@ant-design/icons';
import {
  Dropdown,
  List,
  Menu,
  Typography,
} from 'antd';
import Link from 'next/link';
import { FC } from 'react';

import { CaretDownAlt } from '@/components/Icons';
import constants from '@/constants';
import { useApplication } from '@/context/application';
import { VERSION } from '@/environment';
import externalUrls from '@/externalUrls';
import Routes from '@/routes';
import strings from '@/strings';

import ThemeSwitch from './ThemeSwitch';

const ProfileCard: FC = () => {
  const { user, account } = useApplication();

  if (!user) return <></>;

  return (
    <div className='flex flex-row items-center gap-2 group'>
      <div className='flex-shrink-0 block'>
        <div className='flex items-center'>
          <div>
            <img
              className='inline-block h-9 w-9 rounded-full'
              src={user.picture}
              alt={
                user.name ? strings.avatarOfUser(user.name) : strings.userAvatar
              }
            />
          </div>
          <div className='ml-3'>
            <div className='text-sm font-medium text-gray-700 group-hover:text-gray-900 mb-0 max-w-xs truncate'>
              {account?.name || strings.noLicenseSelected}
            </div>
            <div className='text-xs font-medium text-gray-500 group-hover:text-gray-700 mb-0'>
              {user.name}
            </div>
          </div>
        </div>
      </div>

      <div className='w-8 h-8 text-blue-50 relative'>
        <CaretDownAlt />
      </div>
    </div>
  );
};

export const ProfileMenu: FC = () => {
  const { user, account, checkFlag } = useApplication();
  const showCcrsLogLink = checkFlag(constants.CCRS_FEATURE_FLAG) && (account?.region || '').toUpperCase() === 'WA';

  if (!user) return <></>;

  const navData = [
    { key: strings.user, value: user.email },
    ...(account ?
      [
        { key: strings.account, value: account.name },
        { key: strings.licenseNum, value: account.vendor?.licenseNumber },
        { key: strings.license, value: account.vendor?.licenseType?.name },
        {
          key: strings.mode,
          value: account.complianceCredential?.isSandbox ?
            strings.training :
            strings.production,
        },
      ] :
      []),
  ];
 
  const menu = (
    <Menu data-testid='account-navbar'>
      <List
        size='small'
        dataSource={navData}
        renderItem={(item) => (
          <Menu.Item className='max-w-sm truncate '>
            <Typography.Text strong>
              {item.key}
              {strings.colon}
            </Typography.Text>{' '}
            {item.value}
          </Menu.Item>
        )}
      />

      <Menu.Divider />

      <Menu.Item icon={<SwapOutlined />} key='switchLicense'>
        <Link href={Routes.switchLicense}>{strings.switchLicense}</Link>
      </Menu.Item>

      <Menu.Item icon={<SettingOutlined />} key='userSettings'>
        <Link href={externalUrls.userSettings()}>{strings.userSettings}</Link>
      </Menu.Item>


      <Menu.Item icon={<DashboardOutlined />} key='accountSettings'>
        <Link href={externalUrls.accountSettings()}>
          {strings.accountSettings}
        </Link>
      </Menu.Item>

      {showCcrsLogLink && (
        <Menu.Item icon={<ContainerOutlined />} key='ccrsLog'>
          <Link href={Routes.admin.ccrs}>
            {strings.ccrsLog}
          </Link>
        </Menu.Item>
      )}

      {checkFlag(constants.DARK_MODE_FEATURE_FLAG) && (
        <>
          <Menu.Divider />

          <ThemeSwitch className='ant-menu-item' />
        </>
      )}
      <Menu.Divider />
      <Menu.Item icon={<QuestionCircleOutlined />} key='helpDocs'>
        <Link href={externalUrls.help()}>{strings.helpDocs}</Link>
      </Menu.Item>
      <Menu.Item icon={<UnderlineOutlined />} key='growFlowUniversity'>
        <Link href={externalUrls.growFlowUniversity()}>{strings.growFlowUniversity}</Link>
      </Menu.Item>

      <Menu.Item icon={<ExclamationCircleOutlined />} key='feedback'>
        <Link href={externalUrls.feedback()}>{strings.feedback}</Link>
      </Menu.Item>
      <Menu.Divider />

      <Menu.Item icon={<LogoutOutlined />} key='logout'>
        <Link href={Routes.logout}>{strings.logout}</Link>
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item key='version'>
        <Typography.Text strong>
          {strings.version}
          {strings.colon}
        </Typography.Text>
        <Typography.Text
          style={{ width: 120 }}
          ellipsis={{ tooltip: VERSION }}
          copyable
        >
          {VERSION}
        </Typography.Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown placement='bottomRight' overlay={menu}>
      <a onClick={e => e.preventDefault()}>
        <ProfileCard />
      </a>
    </Dropdown>
  );
};

export default ProfileMenu;
