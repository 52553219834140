import { FunctionComponent } from 'react';

const SvgDashboardAlt: FunctionComponent = () => {
  return (
    <span className='anticon' role='img' aria-label='dashboard-alt'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1015.81 1016.22'
        width='1em'
        height='1em'
      >
        <path
          d='M616.7 471.82h362.83v508.11H616.7zm0-435.53h362.83v218.12H616.7zm-580.41 0h362.78V544.4H36.29zm0 725.52h362.78v218.12H36.29z'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={74}
        />
      </svg>
    </span>
  );
};

export default SvgDashboardAlt;
