import { BulbOutlined } from '@ant-design/icons';
import {
  Space,
  Switch,
  Typography,
} from 'antd';

import constants from '@/constants';
import { useStorage } from '@/hooks/useStorage';
import strings from '@/strings';

enum ThemeBodyClasses {
  DARK = 'raptor-dark-theme',
  LIGHT = 'raptor-light-theme',
}

enum Themes {
  DARK = 'dark',
  LIGHT = 'light'
}

interface Props {
  className: string
}

export default function ThemeSwitch({ className }: Props): JSX.Element {
  const [theme, setTheme] = useStorage(constants.THEME) as [Themes, (arg0: Themes) => void];

  const onClick = () => {
    if (theme === Themes.LIGHT) {
      setTheme(Themes.DARK);
      document.body.classList.add(ThemeBodyClasses.DARK);
      document.body.classList.remove(ThemeBodyClasses.LIGHT);
    } else {
      setTheme(Themes.LIGHT);
      document.body.classList.add(ThemeBodyClasses.LIGHT);
      document.body.classList.remove(ThemeBodyClasses.DARK);
    }
  };

  const isLightTheme = theme === Themes.LIGHT;

  return (
    <label className={className}>
      <BulbOutlined />
      <Space>
        <Switch defaultChecked={!isLightTheme} onClick={onClick}/>
        <Typography.Text>{strings.darkMode}</Typography.Text>
      </Space>
    </label>
  );
}
